import React, { useState, useEffect } from "react";
import "./App.css";
import house from "./assets/house.png";
import aym3 from "./assets/aym3.jpg";
import aym4 from "./assets/aym4.jpg";
import aym5 from "./assets/aym5.jpg";
import aym6 from "./assets/aym6.png";
import aym7 from "./assets/aym7.jpg";
import aym8 from "./assets/aym8.png";
import aym9 from "./assets/aym9.jpg";
import aym10 from "./assets/aym10.png";
import aym11 from "./assets/aym11.jpg";
import axios from "axios";

function App() {
  const targetDate = new Date("2024-02-10T00:00:00");
  const [daysRemaining, setDaysRemaining] = useState(0);
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [tipoMenu, setTipoMenu] = useState("Vegetariano");
  const [transporte, setTransporte] = useState("Si");
  const [errores, setErrores] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const redirectToGoogleMaps = () => {
    const url =
      "https://www.google.com/maps/place/Estancia+Monte+Viejo/@-35.1905276,-58.2664819,17z/data=!3m1!4b1!4m9!3m8!1s0x95a2b610cf26bfdb:0xbb65356280c31b7f!5m2!4m1!1i2!8m2!3d-35.190532!4d-58.263907!16s%2Fg%2F1wn34vn_?entry=ttu";
    window.location.href = url; // Redirige a la página de Google Maps
  };
  const handleScrollToSection = () => {
    const section = document.getElementById("confirmarAsistencia");
    if (section) {
      section.scrollIntoView({ behavior: "smooth" }); // Desplaza suavemente a la sección
    }
  };
  let countRender = 0;
  useEffect(() => {
    console.log("Si entras aqui es para juzgar el codigo!");
    console.log("Pero no pasa nada!");
    console.log("Juzga tranquilo");
    console.log("Te quiero bro!");
    console.warn((countRender += 1));
  }, []);

  useEffect(() => {
    // Función para actualizar los días restantes
    const updateDaysRemaining = () => {
      const now = new Date();
      const difference = targetDate - now;
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      setDaysRemaining(days);
    };
    const interval = setInterval(updateDaysRemaining, 1000);
    return () => clearInterval(interval);
  }, []);
  const validarFormulario = () => {
    let erroresTemp = {};
    if (!nombre.trim()) {
      erroresTemp.nombre = "El nombre es requerido";
    }
    if (!apellido.trim()) {
      erroresTemp.apellido = "El apellido es requerido";
    }
    // Puedes agregar más validaciones aquí

    setErrores(erroresTemp);
    return Object.keys(erroresTemp).length === 0;
  };

  const handleSubmit = async (e) => {
    setDisabledButton(true);
    e.preventDefault();
    const URLMethod =
      "https://script.google.com/macros/s/AKfycbyyVdRMu_UWRljO_C2Q3ABalf5g0OoS_e3vBOhYpHPm3CNRVcN9NYA-fFC-L-U59YA/exec";
    if (validarFormulario()) {
      // Los datos que quieres enviar
      const formData = new FormData();
      formData.append("nombre", nombre);
      formData.append("apellido", apellido);
      formData.append("tipoMenu", tipoMenu);
      formData.append("transporte", transporte);
      try {
        const response = await axios({
          method: "post",
          url: URLMethod,
          data: formData,
          headers: {
            // `axios` establece automáticamente el Content-Type a 'multipart/form-data' cuando se envía un `FormData`
          },
        });

        if (response.status === 200) {
          setDisabledButton(false);
          togglePopup(true);
          // Puedes hacer algo más aquí, como redirigir al usuario o mostrar un mensaje de éxito
        }
      } catch (error) {
        setDisabledButton(false);
        console.error("Error al enviar datos:", error);
        // Maneja el error de acuerdo a tus necesidades
      }
    }
  };

  return (
    <div className="App">
      <section className="fullvh section-center">
        <div className="padding-top-3">
          <img className="houselogo" src={house} alt="house" />
          <div className="margin-left-logo-title">
            <h2 className="text-color-1 text-beyond font-size-4 margin-bottom-0 margin-top-5 ">
              Nos casamos
            </h2>
            <h2 className="text-color-1 text-cormorant font-size-title-monte">
              AGUSTINA Y MIGUELANGEL
            </h2>
            <h2 className="text-color-1 text-cormorant font-size-title-conteo">
              Faltan {daysRemaining} días para el 10 de febrero de 2024
            </h2>
          </div>
        </div>
      </section>
      <section className="fullvh section-center">
        <div className="grid-1">
          <div>
            <img
              className="image1 margin-imagen1 rounded-image1"
              src={aym3}
              alt="my1"
            />
          </div>
          <div className="margin-cards-1">
            <h2 className="text-color-1  text-beyond font-size-cursiva margin-bottom-0 margin-top-0">
              Ceremonia
            </h2>
            <h1 className="text-cormorant text-color-1">
              Estancia Monte Viejo
              <br />
              Ruta 215, KM 51 - Brandsen
              <br />
              Provincia de Buenos Aires
              <br />
              18hs
              <br />
            </h1>
            <button
              className="boton-boda text-cormorant"
              onClick={redirectToGoogleMaps}
            >
              Cómo llegar
            </button>
          </div>
        </div>
      </section>
      <section className="fullvh section-center">
        <div className="grid-2">
          <div>
            <img
              className="image1 margin-imagen1 rounded-image1"
              src={aym6}
              alt="my1"
            />
          </div>
          <div className="margin-cards-2 margin-bottom-1-response">
            <h2 className=" text-color-1 text-beyond text-size-compartir margin-bottom-0 margin-top-0">
              Los invitamos a compartir
            </h2>
            <h2 className="text-cormorant max-with-text-1 font-size-2 text-color-1">
              El día de nuestro casamiento
              <br />
            </h2>
            <h1 className="text-cormorant max-with-text-1 font-size-0 text-color-1">
              Confirmar asistencia antes del 10 de enero de 2024
            </h1>
            <button
              className="text-cormorant boton-boda"
              onClick={handleScrollToSection}
            >
              Confirmar asistencia
            </button>
          </div>
        </div>
      </section>
      <section className="fullvh section-center" id="confirmarAsistencia">
        <div className="grid-2">
          <div>
            <img
              className="image2 margin-imagen1 rounded-image1"
              src={aym9}
              alt="my1"
            />
          </div>
          <div className="">
            <h2 className="text-cormorant font-size-1 text-color-1">
              Información importante
            </h2>
            <form>
              <div className="margin-bottom-1">
                <input
                  className="input-rounded margin-bottom-0-1"
                  type="text"
                  name="nombre"
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                  placeholder="Nombre"
                />
                {errores.nombre && <p className="error">{errores.nombre}</p>}
                <input
                  className="input-rounded margin-bottom-0-1"
                  type="text"
                  name="apellido"
                  value={apellido}
                  onChange={(e) => setApellido(e.target.value)}
                  placeholder="Apellido"
                />
                {errores.apellido && (
                  <p className="error">{errores.apellido}</p>
                )}
              </div>
              <div className="caontainerSelects">
                <div className="caontainerSelects widthSelects margin-bottom-1">
                  <span className="text-color-1 text-cormorant font-size-0">
                    Seleccioná el menú que prefieras:
                  </span>
                  <select
                    className="select-rounded"
                    value={tipoMenu}
                    onChange={(e) => setTipoMenu(e.target.value)}
                  >
                    <option value="Vegetariano">Vegetariano</option>
                    <option value="Vegano">Vegano</option>
                    <option value="Celiaco">Sin TACC</option>
                    <option value="Sin restricciones">Sin restricciones</option>
                    <option value="No asistire">no asistiré</option>
                  </select>
                </div>
                <div className="caontainerSelects widthSelects ">
                  <span className="text-color-1 text-cormorant font-size-0 ">
                    ¿Querés reservar un transporte para el día del evento?
                  </span>
                  <select
                    className="select-rounded margin-bottom-1"
                    value={transporte}
                    onChange={(e) => setTransporte(e.target.value)}
                  >
                    <option value="Si">Sí</option>
                    <option value="No">No</option>
                  </select>
                  <button
                    className="boton-boda text-cormorant"
                    onClick={handleSubmit}
                    disabled={disabledButton}
                  >
                    Confirmar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <section className="fullvh section-center" id="confirmarAsistencia">
        <div className="grid-1">
          <div className="margin-top-fix">
            <img
              className="image3 margin-cards-2 rounded-image1"
              src={aym11}
              alt="my1"
            />
          </div>
          <div className="">
            <h2 className="text-color-1 text-beyond text-size-ayudarnos margin-bottom-0 margin-top-0">
              Si quieres ayudarnos con nuestra luna de miel
            </h2>
            <h1 className="text-color-1 text-cormorant">
              <br />
              Mercado Pago:
              <br />
              Agustina de la Rosa
              <br />
              Alias: Genjiymomo
              <br />
              <br />
              Binance:
              <br />
              Miguelangel Rojas
              <br />
              Email: patitoremesas@gmail.com
              <br />
              <h5 className="font-size-sobre text-cormorant">
                También puedes dejarnos un sobre en mano
              </h5>
              <br />
            </h1>
          </div>
        </div>
      </section>
      <section className="fullvh section-center">
        <div className="grid-2">
          <div>
            <img
              className="image1 margin-cards-1 rounded-image1"
              src={aym8}
              alt="my1"
            />
          </div>
          <div>
            <h2 className="text-color-1 text-beyond font-size-cursiva margin-bottom-0 margin-top-0">
              Dress Code
            </h2>
            <h1 className="text-color-1 text-cormorant">Elegante</h1>
            <h1 className="text-color-1 text-cormorant">
              Evitar usar azul marino
            </h1>
          </div>
        </div>
      </section>
      <section className="fullvh section-center">
        <div className="grid-1">
          <div>
            <img
              className="image1 margin-cards-2 rounded-image1"
              src={aym5}
              alt="my1"
            />
          </div>
          <div>
            <h2 className="text-color-1 text-beyond font-size-cursiva margin-bottom-0 margin-top-0">
              Contacto
            </h2>
            <h1 className="text-color-1 text-cormorant">
              Cane - Wedding Planner
              <br />
              +54 11 3426 2875
            </h1>
            <h1 className="text-color-1 text-cormorant">
              Nat - Wedding Planner
              <br /> +54 9 11 2482-0110
            </h1>
          </div>
        </div>
      </section>
      {isOpen && (
        <div className="popup">
          <div className="popup-content">
            <span className="close-button" onClick={togglePopup}>
              &times;
            </span>
            <h2 className="text-cormorant max-with-text-1 font-size-2">
              ¡Gracias!
            </h2>
            <p className="text-cormorant max-with-text-1">
              Información enviada correctamente
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
